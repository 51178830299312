<template>
  <div>
    <div class="popup" @click="togglePopup">
      <div class="popup_wrapper">
        <div class="popup_title">
        </div>
        <div class="popup_descr"></div>
        <div class="popup_close" @click="togglePopup">
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 24 24">
            <path d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z"></path>
          </svg>
        </div>
      </div>
    </div>
    <div class="addProdPopup">
      <svg height="24px" width="24px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 17.837 17.837" xml:space="preserve">
        <g>
          <path style="fill:#fff;" d="M16.145,2.571c-0.272-0.273-0.718-0.273-0.99,0L6.92,10.804l-4.241-4.27   c-0.272-0.274-0.715-0.274-0.989,0L0.204,8.019c-0.272,0.271-0.272,0.717,0,0.99l6.217,6.258c0.272,0.271,0.715,0.271,0.99,0   L17.63,5.047c0.276-0.273,0.276-0.72,0-0.994L16.145,2.571z"/>
        </g>
      </svg>
      Produs adăugat cu success
    </div>
    <vHeader/>
      <div class="promo">
        <div class="promo_logo">
          <img src="https://static.tildacdn.com/tild6336-3338-4533-b462-383461366230/1Asset_5.svg" alt="">
        </div>
        <div class="promo_descr">
          ISTORII CU CASCAVAL este o rețea de magazine de brânzeturi EUROPENE cu atmosferă, unde ți se vor oferi diverse gusturi și ți se va spune totul despre brânzeturi, și vei fi ajutat să alegi vinuri, sosuri și accesorii pentru cașcaval.
        </div>
        <div class="t-cover__arrow">
          <div class="t-cover__arrow-wrapper t-cover__arrow-wrapper_animated">
            <div class="t-cover__arrow_mobile">
              <svg role="presentation" class="t-cover__arrow-svg" x="0px" y="0px" width="38.417px" height="18.592px" viewBox="0 0 38.417 18.592" style="enable-background:new 0 0 38.417 18.592;"><g><path d="M19.208,18.592c-0.241,0-0.483-0.087-0.673-0.261L0.327,1.74c-0.408-0.372-0.438-1.004-0.066-1.413c0.372-0.409,1.004-0.439,1.413-0.066L19.208,16.24L36.743,0.261c0.411-0.372,1.042-0.342,1.413,0.066c0.372,0.408,0.343,1.041-0.065,1.413L19.881,18.332C19.691,18.505,19.449,18.592,19.208,18.592z"></path></g></svg>
            </div>
          </div>
        </div>
      </div>
    <div class="wrapper">
      <vCatalog/>
      <vCart
          :cart_data="CART"
      />
    </div>
    <vFooter/>
  </div>
</template>

<script>
import vCatalog from './v-catalog';
import vCart from './v-cart';
import vHeader from './v-header.vue';
import vFooter from './v-footer.vue';
import {mapGetters} from 'vuex';

export default {
  name: "v-main-wrapper",
  components: {
    vCatalog,
    vCart,
    vHeader,
    vFooter,
  },
  props: {},
  data() {
    return {
      products: [],
    };
  },
  computed: {
    ...mapGetters(["CART"]),
  },
  methods: {
    cartItemProd(item) {
      this.products.push(item);
    },
    togglePopup() {
      const popup = document.querySelector('.popup');
      const popupContent = document.querySelector('.popup_wrapper');
      popup.classList.toggle('popup_active');
      popupContent.addEventListener('click', (e) => {
        e.stopPropagation();
      })
    },

  }
};
</script>

<style>
.addProdPopup{
  top: 60px;
  width: 235px;
  max-width: 100%;
  font-size: .875rem;
  pointer-events: auto;
  background-clip: padding-box;
  box-shadow: 0 0.5rem 1rem rgba(40,167,69,.15);
  border-radius: 0.25rem;
  position: fixed;
  background: #28a745;
  z-index: 999;
  opacity: 0;
  display: flex;
  align-items: center;
  color: #fff;
  visibility: hidden;
  padding: 0.75rem;
  right: 15px;
}
.addProdPopup>svg{
  margin-right: 10px;
}

.addProdPopup.active{
  opacity: 1;
  visibility: visible;
  transition: 0.5s all;
}
.promo{
  margin-top: 100px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px;
  background: url("https://thumb.tildacdn.com/tild3531-3039-4237-b262-616666326661/-/format/webp/photo_2023-05-09_215.jpeg") center center/cover no-repeat;
}

.promo::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: -webkit-linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7));
  z-index: 1;
}

.promo_logo{
  position: relative;
  z-index: 2;
  padding-bottom: 30px;
  margin: 0 auto;
  max-width: 250px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.promo_logo>img{
  width: 100%;
}
.promo_descr{
  position: relative;
  z-index: 2;
  text-align: center;
  color: #fff;
  padding: 0 0 30px 0;
  max-width: 700px;
  width: 100%;
  font-size: 24px;
  line-height: 1.5;
  margin: 0 auto;
}
@-webkit-keyframes t-arrow-bottom {
  0%,
  to {
    -moz-transform:translateY(0);
    -ms-transform:translateY(0);
    -webkit-transform:translateY(0);
    -o-transform:translateY(0);
    transform:translateY(0)
  }
  50%,
  55% {
    -moz-transform:translateY(-7px);
    -ms-transform:translateY(-7px);
    -webkit-transform:translateY(-7px);
    -o-transform:translateY(-7px);
    transform:translateY(-7px)
  }
}
@keyframes t-arrow-bottom {
  0%,
  to {
    -moz-transform:translateY(0);
    -ms-transform:translateY(0);
    -webkit-transform:translateY(0);
    -o-transform:translateY(0);
    transform:translateY(0)
  }
  50%,
  55% {
    -moz-transform:translateY(-7px);
    -ms-transform:translateY(-7px);
    -webkit-transform:translateY(-7px);
    -o-transform:translateY(-7px);
    transform:translateY(-7px)
  }
}
.t-cover,
.t-cover__carrier {
  height:700px;
  width:100%;
  background-size:cover;
  -webkit-background-size:cover;
  -moz-background-size:cover;
  -o-background-size:cover;
  background-repeat:no-repeat;
  background-position:center center;
  text-align:center;
  vertical-align:middle;
  position:relative;
  background-attachment:fixed
}
.t-cover {
  background-color:#000;
  overflow:hidden
}
.t-cover.t-cover__transparent {
  background-color:transparent
}
.t-cover__carrier {
  -moz-transform:unset
}
@supports (-ms-ime-align:auto) {
  .t-cover__carrier {
    transform:unset
  }
}
.t-cover__carrier.loading {
  opacity:0
}
.t-cover__carrier[data-content-cover-bg=''].loading {
  opacity:1!important
}
.t-cover__carrier.loaded {
  opacity:1;
  transition:opacity 700ms
}
@media screen and (max-device-width:1024px) {
  .t-cover,
  .t-cover__carrier {
    background-attachment:scroll
  }
}
@media print {
  .t-cover,
  .t-cover__carrier {
    background-attachment:scroll
  }
}
.t-cover__filter {
  height:700px;
  width:100%;
  position:absolute;
  top:0;
  left:0
}
.t-cover .t-container,
.t-cover .t-container_10,
.t-cover .t-container_100,
.t-cover .t-container_8 {
  position:absolute;
  top:0;
  left:0;
  bottom:0;
  right:0
}
.t-cover__wrapper {
  height:700px;
  display:table-cell;
  width:1200px
}
.t-cover__wrapper span.space {
  display:inline-block;
  height:100%;
  width:1px
}
@media screen and (max-width:640px) {
  .t-cover {
    background-attachment:fixed
  }
  .t-cover__carrier {
    background-attachment:scroll!important;
    background-size:cover;
    background-position:center center
  }
}
.t-cover__arrow-wrapper_animated {
  animation:t-arrow-bottom 1.7s infinite ease;
}
.t-cover__arrow {
  position:absolute;
  z-index:9;
  bottom:40px;
  right:0;
  left:0;
  text-align:center
}
.t-cover__arrow-wrapper {
  display:inline-block;
  -webkit-transition:all ease-in-out .2s;
  -moz-transition:all ease-in-out .2s;
  -o-transition:all ease-in-out .2s;
  transition:all ease-in-out .2s;
}
.t-cover__arrow-wrapper:hover {
  opacity:.7
}
.t-cover__arrow-svg {
  fill:#fff
}

@media(max-width: 980px){
  .promo{
    margin-top: 64px;
  }
}
@media screen and (max-width:640px) {
  .t-cover__arrow_mobile {
    -moz-transform:scale(.7);
    -ms-transform:scale(.7);
    -webkit-transform:scale(.7);
    -o-transform:scale(.7);
    transform:scale(.7)
  }
  .t-cover__arrow {
    bottom:14px
  }
  .promo{
    padding: 100px 15px;
  }
}

</style>
