<template>
    <div class="header">
        <div class="header_wrapper">
            <div class="header_logo">
                <img :src="require('@/assets/logo.png')" alt="">
            </div>
            <ul class="header_menu">
                <li class="header_menu_item">
                    <a href="#" class="header_menu_item_link">Catalog</a>
                </li>
                <li class="header_menu_item">
                    <a href="#" class="header_menu_item_link">Despre noi</a>
                </li>
                <div class="header_menu_item">
                    <a href="#" class="header_menu_item_link">Franciză</a>
                </div>
                <div class="header_menu_item">
                    <a href="#" class="header_menu_item_link">Contacte</a>
                </div>
            </ul>
            <div class="header_contacts">
                <div class="header_phone">
                    <a href="tel:+37379903333" class="header_phone_link">+37379903333</a>
                </div>
                <div class="header_socials">
                    <a href='#' class="header_social_item">
                        <svg class="t-sociallinks__svg" width="30px" height="30px" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M50 100c27.6142 0 50-22.3858 50-50S77.6142 0 50 0 0 22.3858 0 50s22.3858 50 50 50Zm3.431-73.9854c-2.5161.0701-5.171.6758-7.0464 2.4577-1.5488 1.4326-2.329 3.5177-2.5044 5.602-.0534 1.4908-.0458 2.9855-.0382 4.4796.0058 1.1205.0115 2.2407-.0085 3.3587-.6888.005-1.3797.0036-2.0709.0021-.9218-.0019-1.8441-.0038-2.7626.0096 0 .8921.0013 1.7855.0026 2.6797.0026 1.791.0052 3.5853-.0026 5.3799.9185.0134 1.8409.0115 2.7627.0096.6912-.0015 1.382-.0029 2.0708.0021.0155 3.5565.0127 7.1128.0098 10.669-.0036 4.4452-.0072 8.8903.0252 13.3354 1.8903-.0134 3.7765-.0115 5.6633-.0095 1.4152.0014 2.8306.0028 4.2484-.0022.0117-4.0009.0088-7.9986.0058-11.9963-.0029-3.9979-.0058-7.9957.0059-11.9964.9533-.005 1.9067-.0036 2.86-.0021 1.2713.0019 2.5425.0038 3.8137-.0096.396-2.679.7335-5.3814.9198-8.0947-1.2576-.0058-2.5155-.0058-3.7734-.0058-1.2578 0-2.5157 0-3.7734-.0059 0-.4689-.0007-.9378-.0014-1.4066-.0022-1.4063-.0044-2.8123.0131-4.2188.198-1.0834 1.3158-1.9104 2.3992-1.8403h5.1476c.0117-2.8069.0117-5.602 0-8.4089-.6636 0-1.3273-.0007-1.9911-.0014-1.9915-.0022-3.9832-.0044-5.975.0131Z" fill="#000000"></path></svg>
                    </a>
                    <a href="#" class="header_social_item">
                        <svg class="t-sociallinks__svg" width="30px" height="30px" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100ZM25 39.3918C25 31.4558 31.4566 25 39.3918 25H60.6082C68.5442 25 75 31.4566 75 39.3918V60.8028C75 68.738 68.5442 75.1946 60.6082 75.1946H39.3918C31.4558 75.1946 25 68.738 25 60.8028V39.3918ZM36.9883 50.0054C36.9883 42.8847 42.8438 37.0922 50.0397 37.0922C57.2356 37.0922 63.0911 42.8847 63.0911 50.0054C63.0911 57.1252 57.2356 62.9177 50.0397 62.9177C42.843 62.9177 36.9883 57.1252 36.9883 50.0054ZM41.7422 50.0054C41.7422 54.5033 45.4641 58.1638 50.0397 58.1638C54.6153 58.1638 58.3372 54.5041 58.3372 50.0054C58.3372 45.5066 54.6145 41.8469 50.0397 41.8469C45.4641 41.8469 41.7422 45.5066 41.7422 50.0054ZM63.3248 39.6355C65.0208 39.6355 66.3956 38.2606 66.3956 36.5646C66.3956 34.8687 65.0208 33.4938 63.3248 33.4938C61.6288 33.4938 60.2539 34.8687 60.2539 36.5646C60.2539 38.2606 61.6288 39.6355 63.3248 39.6355Z" fill="#000000"></path></svg>
                    </a>
                    <a href="#" class="header_social_item">
                        <svg class="t-sociallinks__svg" width="30px" height="30px" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M50 100c27.614 0 50-22.386 50-50S77.614 0 50 0 0 22.386 0 50s22.386 50 50 50Zm5.495-74.986c-1.555.002-3.113.004-4.676.028l.001.002c-.04 5.37-.038 10.742-.035 16.12.001 3.007.003 6.015-.004 9.026-.006 1.01-.005 2.019-.004 3.027.002 2.712.005 5.421-.144 8.138-.022.754-.4 1.417-.767 2.06l-.088.154c-1.212 1.977-3.442 3.327-5.769 3.352-3.51.31-6.797-2.563-7.292-5.982-.004-.167-.01-.336-.016-.505-.034-.964-.069-1.948.298-2.846.522-1.482 1.524-2.791 2.843-3.646 1.806-1.255 4.222-1.442 6.29-.773 0-1.318.022-2.636.044-3.953.03-1.767.06-3.534.036-5.303-4.525-.846-9.358.585-12.808 3.59-3.044 2.58-5.005 6.372-5.38 10.336a34.879 34.879 0 0 0 .025 3.1c.431 4.876 3.392 9.418 7.6 11.9 2.54 1.496 5.516 2.309 8.496 2.139 4.858-.082 9.588-2.686 12.313-6.682 1.694-2.4 2.655-5.299 2.818-8.211.042-4.001.04-8.01.036-12.023 0-2.068-.002-4.138.003-6.208 1.082.708 2.189 1.4 3.376 1.938 2.727 1.293 5.746 1.919 8.75 2.017v-8.39c-3.206-.357-6.501-1.415-8.83-3.726-2.335-2.255-3.482-5.504-3.646-8.693-1.156.01-2.312.012-3.47.014Z" fill="#000000"></path></svg>
                    </a>
                </div>
            </div>
          <div class="cart_icon" @click="toggleCart">
            <svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" style="width: 5em; height: 5em;vertical-align: middle;fill: currentColor;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1">
              <path d="M831.456 879.84H192.848a15.888 15.888 0 0 1-15.888-17.76l33.04-480a16.112 16.112 0 0 1 15.888-14.24h126.864V304a160 160 0 0 1 320 0v63.84h127.68a16.112 16.112 0 0 1 15.904 14.4l30.4 476.8a16 16 0 0 1-15.28 20.8zM640.752 304a128 128 0 0 0-256 0v63.84h256V304z m145.328 95.84h-113.328v36.64a31.648 31.648 0 0 1 16 27.52 32 32 0 0 1-64 0 31.648 31.648 0 0 1 16-27.52v-36.64h-256v36.64a31.648 31.648 0 0 1 16 27.52 32 32 0 0 1-64 0 31.648 31.648 0 0 1 16-27.52v-36.64h-112.576l-29.408 448h602.528z" fill="#333333"></path>
            </svg>
            <span class="count font-xs">
              {{ total }}
            </span>
          </div>
        </div>
    </div>
    <div class="header_mobile">
      <div class="header_mobile_wrapper">
        <button type="button" @click="burger_init" class="burger t-menuburger t-menuburger_first" aria-label="Навигационное меню" aria-expanded="false">
          <span style="background-color:#ffffff;"></span>
          <span style="background-color:#ffffff;"></span>
          <span style="background-color:#ffffff;"></span>
          <span style="background-color:#ffffff;"></span>
        </button>
      </div>
      <div class="header_mobile_content">
        <div class="header_mobile_logo">
          <img :src="require('@/assets/logo.png')" alt="">
        </div>
        <ul class="header_mobile_menu">
          <li class="header_mobile_menu_item">
            <a href="#" class="header_mobile_menu_item_link">Catalog</a>
          </li>
          <li class="header_mobile_menu_item">
            <a href="#" class="header_mobile_menu_item_link">Despre noi</a>
          </li>
          <div class="header_mobile_menu_item">
            <a href="#" class="header_mobile_menu_item_link">Franciză</a>
          </div>
          <div class="header_mobile_menu_item">
            <a href="#" class="header_mobile_menu_item_link">Contacte</a>
          </div>
        </ul>
        <div class="header_mobile_contacts">
          <div class="header_mobile_phone">
            <a href="tel:+37379903333" class="header_mobile_phone_link">+37379903333</a>
          </div>
          <div class="header_mobile_socials">
            <a href='#' class="header_mobile_social_item">
              <svg class="t-sociallinks__svg" width="30px" height="30px" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M50 100c27.6142 0 50-22.3858 50-50S77.6142 0 50 0 0 22.3858 0 50s22.3858 50 50 50Zm3.431-73.9854c-2.5161.0701-5.171.6758-7.0464 2.4577-1.5488 1.4326-2.329 3.5177-2.5044 5.602-.0534 1.4908-.0458 2.9855-.0382 4.4796.0058 1.1205.0115 2.2407-.0085 3.3587-.6888.005-1.3797.0036-2.0709.0021-.9218-.0019-1.8441-.0038-2.7626.0096 0 .8921.0013 1.7855.0026 2.6797.0026 1.791.0052 3.5853-.0026 5.3799.9185.0134 1.8409.0115 2.7627.0096.6912-.0015 1.382-.0029 2.0708.0021.0155 3.5565.0127 7.1128.0098 10.669-.0036 4.4452-.0072 8.8903.0252 13.3354 1.8903-.0134 3.7765-.0115 5.6633-.0095 1.4152.0014 2.8306.0028 4.2484-.0022.0117-4.0009.0088-7.9986.0058-11.9963-.0029-3.9979-.0058-7.9957.0059-11.9964.9533-.005 1.9067-.0036 2.86-.0021 1.2713.0019 2.5425.0038 3.8137-.0096.396-2.679.7335-5.3814.9198-8.0947-1.2576-.0058-2.5155-.0058-3.7734-.0058-1.2578 0-2.5157 0-3.7734-.0059 0-.4689-.0007-.9378-.0014-1.4066-.0022-1.4063-.0044-2.8123.0131-4.2188.198-1.0834 1.3158-1.9104 2.3992-1.8403h5.1476c.0117-2.8069.0117-5.602 0-8.4089-.6636 0-1.3273-.0007-1.9911-.0014-1.9915-.0022-3.9832-.0044-5.975.0131Z" fill="#000000"></path></svg>
            </a>
            <a href="#" class="header_social_item">
              <svg class="t-sociallinks__svg" width="30px" height="30px" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100ZM25 39.3918C25 31.4558 31.4566 25 39.3918 25H60.6082C68.5442 25 75 31.4566 75 39.3918V60.8028C75 68.738 68.5442 75.1946 60.6082 75.1946H39.3918C31.4558 75.1946 25 68.738 25 60.8028V39.3918ZM36.9883 50.0054C36.9883 42.8847 42.8438 37.0922 50.0397 37.0922C57.2356 37.0922 63.0911 42.8847 63.0911 50.0054C63.0911 57.1252 57.2356 62.9177 50.0397 62.9177C42.843 62.9177 36.9883 57.1252 36.9883 50.0054ZM41.7422 50.0054C41.7422 54.5033 45.4641 58.1638 50.0397 58.1638C54.6153 58.1638 58.3372 54.5041 58.3372 50.0054C58.3372 45.5066 54.6145 41.8469 50.0397 41.8469C45.4641 41.8469 41.7422 45.5066 41.7422 50.0054ZM63.3248 39.6355C65.0208 39.6355 66.3956 38.2606 66.3956 36.5646C66.3956 34.8687 65.0208 33.4938 63.3248 33.4938C61.6288 33.4938 60.2539 34.8687 60.2539 36.5646C60.2539 38.2606 61.6288 39.6355 63.3248 39.6355Z" fill="#000000"></path></svg>
            </a>
            <a href="#" class="header_social_item">
              <svg class="t-sociallinks__svg" width="30px" height="30px" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M50 100c27.614 0 50-22.386 50-50S77.614 0 50 0 0 22.386 0 50s22.386 50 50 50Zm5.495-74.986c-1.555.002-3.113.004-4.676.028l.001.002c-.04 5.37-.038 10.742-.035 16.12.001 3.007.003 6.015-.004 9.026-.006 1.01-.005 2.019-.004 3.027.002 2.712.005 5.421-.144 8.138-.022.754-.4 1.417-.767 2.06l-.088.154c-1.212 1.977-3.442 3.327-5.769 3.352-3.51.31-6.797-2.563-7.292-5.982-.004-.167-.01-.336-.016-.505-.034-.964-.069-1.948.298-2.846.522-1.482 1.524-2.791 2.843-3.646 1.806-1.255 4.222-1.442 6.29-.773 0-1.318.022-2.636.044-3.953.03-1.767.06-3.534.036-5.303-4.525-.846-9.358.585-12.808 3.59-3.044 2.58-5.005 6.372-5.38 10.336a34.879 34.879 0 0 0 .025 3.1c.431 4.876 3.392 9.418 7.6 11.9 2.54 1.496 5.516 2.309 8.496 2.139 4.858-.082 9.588-2.686 12.313-6.682 1.694-2.4 2.655-5.299 2.818-8.211.042-4.001.04-8.01.036-12.023 0-2.068-.002-4.138.003-6.208 1.082.708 2.189 1.4 3.376 1.938 2.727 1.293 5.746 1.919 8.75 2.017v-8.39c-3.206-.357-6.501-1.415-8.83-3.726-2.335-2.255-3.482-5.504-3.646-8.693-1.156.01-2.312.012-3.47.014Z" fill="#000000"></path></svg>
            </a>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
export default {
    "name": "v-header",
    data(){
      return {
        total: 0
      }
    },
    methods: {
      toggleCart(){
        document.querySelector('.cart').classList.toggle('active')
      },
      burger_init(){
        document.querySelector(".burger").classList.toggle("t-menuburger-opened");
        document.querySelector('.header_mobile_content').classList.toggle('active');
      }
    },
    watch: {
      "$store.state.cart": {
        handler(newVal) {
          this.total = newVal.reduce((total, item) => total + (item.qty || 0), 0);
        },
        deep: true,
      },
    },
}
</script>
<style>
    .header{
        position: fixed;
        width: 100%;
        padding: 0 40px;
        z-index: 9;
        top: 0;
        height: 100px;
        background: #fff;
        box-shadow: 0px 0px 8px rgba(0,0,0,0.3);
    }

    .header_wrapper{
        display: flex;
        justify-content: space-between;
        height: 100%;
        align-items: center;
    }
    .header_logo{
        margin-right: 30px;
    }
    .header_logo>img{
        width: 100%;
        max-height: 80px;
    }

    .header_menu{
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    .header_menu_item{
        margin-left: 15px;
        color: #000000;
        font-size: 18px;
        font-weight: 400;
    }
    .header_contacts{
        max-width: 300px;
        width: 100%;
        display: flex;
        align-items: center;
    }
    .header_phone{
        font-size: 18px;
        font-weight: 400;
        color: #000;
        padding-left: 30px;
    }
    .header_socials{
        display: flex;
        align-items: center;
        gap:5px;
        padding-left: 30px;
    }
</style>
