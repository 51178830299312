import { createStore } from 'vuex';
import axios from 'axios';
const store = createStore({

    state: {
        products: [],
        cart: [],
    },
    mutations: {
        updateCartItemQty(state, payload) {
            const { productId, newQty } = payload;
            const cartItem = state.cart.find(item => item.id === productId);

            if (cartItem) {
                cartItem.qty = newQty;
            }
        },
        SET_PRODUCTS_TO_STATE: (state, products) => {
            state.products = products;
        },
        SET_CART: (state, product) => {
            if (!product || !product.id || typeof product.qty === 'undefined') {
                return;
            }


            const existingProduct = state.cart.find(item => item.id === product.id);
            if (existingProduct) {
                existingProduct.qty += product.qty;
            } else {
                state.cart.push({ ...product });
            }

            localStorage.setItem('cart_data', JSON.stringify(state.cart));

            if(JSON.parse(localStorage.getItem('cartActive')) != null){
                document.querySelector('.cart').classList.add('active');
                localStorage.setItem('cartActive', true);
            }
        },

        REMOVE_FROM_CART: (state, index) => {
            state.cart.splice(index, 1);
            localStorage.setItem('cart_data', JSON.stringify(state.cart));
        },
        CLEAR_FROM_CART: (state) => {
            state.cart = [];
            localStorage.setItem('cart_data', JSON.stringify(state.cart));
        },
        INIT_CART_DOM: (state) => {
            // const cartData = product;
            // console.log();
            const flatArray = JSON.parse(localStorage.getItem('cart_data'));
            // console.log(flatArray);
            state.cart.push(...flatArray);
        },
    },
    actions: {
        GET_PRODUCTS_FROM_API({ commit }) {
            return axios.get('https://dumitras.com/api.php')
                .then((response) => {
                    commit("SET_PRODUCTS_TO_STATE", response.data);
                    // console.log(response.data)
                    return response.data;
                })
                .catch((error) => {
                    if (error.response) {
                        // Сервер вернул статус отличный от 2xx
                        console.error('Ошибка ответа:', error.response.data);
                        console.error('Статус кода:', error.response.status);
                        console.error('Заголовки:', error.response.headers);
                    } else if (error.request) {
                        // Запрос был сделан, но ответ не был получен
                        console.error('Ошибка запроса:', error.request);
                    } else {
                        // Что-то другое вызвало ошибку
                        console.error('Ошибка сообщения:', error.message);
                    }
                    console.error('Конфигурация запроса:', error.config);
                    throw error; // Перебросить ошибку для дальнейшей обработки
                });
        },
        ADD_TO_CART({commit}, product){
            commit("SET_CART", product)
        },
        DELETE_FROM_CART({commit}, index){
            commit("REMOVE_FROM_CART", index)
        },
        CLEAR_CART({commit}){
            commit("CLEAR_FROM_CART")
        },
        INIT_CART: ({commit}) => {
            commit("INIT_CART_DOM")
        },



    },
    getters: {
        PRODUCTS(state) {
            return state.products;
        },
        CART(state){
            return state.cart;
        }
    }
});
export default store;
