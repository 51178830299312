
<template>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap" rel="stylesheet">
<vMainWrapper/>
</template>

<script>
import vMainWrapper from './components/v-main-wrapper.vue'
export default {
  name: 'App',
  components: {
    vMainWrapper
  }
}
</script>

<style>

</style>
