<template>
    <div class="product">
      <div class="product_img">
        <img :src="prod.images && prod.images[0]" alt="" class="default">
        <img :src="!prod.images[1] ? prod.images[0] : prod.images[1]" alt="" class="hover">
      </div>
      <div class="product_title">{{ prod.title }}

        <span @click="togglePopup(this.prod)">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 24 24">
                <path d="M12,2C6.477,2,2,6.477,2,12s4.477,10,10,10s10-4.477,10-10S17.523,2,12,2z M13,17h-2v-6h2V17z M13,9h-2V7h2V9z"></path>
            </svg>
        </span>
      </div>
      <div class="product_action_wrapper">
        <div class="product_wrapper">
          <div class="product_select_offer" v-if="prod.offers && prod.offers.length">
            <select name="offers" class="product_offers"  v-model="selectedOfferId">
              <option v-for="offer in prod.offers" :value="offer.id" :key="offer.id">{{ offer.name }}</option>
            </select>
          </div>
          <div class="product_action">
          <div class="product_minus" @click="changeCounter('-1')">
            -
          </div>
          <div class="product_score">{{ counter }}</div>
          <div class="product_plus" @click="changeCounter('1')">
            +
          </div>
        </div>
        </div>
        <div class="product_price">{{ prod.price }} MDL.</div>
      </div>
      <div class="btn product_add" @click="addToCartProd">Adaugare</div>
    </div>
  </template>


  <script>
  export default {
    name: "v-catalog-item",
    components: {},
    props: {
      prod: {
        type: Object,
        default() {
          return {

          };
        },
      },
    },
    data() {
      return {
        counter: 1,
        selectedOfferId: null,
      };
    },
    mounted() {
      if (this.prod.offers && this.prod.offers.length > 0) {
        this.selectedOfferId = this.prod.offers[0].id;
      }
    },
    emits: ['addToCart'],
    methods: {
      togglePopup(item) {
        const popup = document.querySelector('.popup');
        const popupContent = document.querySelector('.popup_wrapper');
        popup.classList.toggle('popup_active');
        popup.querySelector('.popup_title').innerHTML = item.title;
        popup.querySelector('.popup_descr').innerHTML = item.descr;
        popupContent.addEventListener('click', (e) => {
            e.stopPropagation();
        })
      },
      changeCounter: function(num){
        this.counter += +num
        !isNaN(this.counter) && this.counter > 0 ? this.counter : this.counter = 1;

      },
      addToCartProd() {
        let finalPrice = this.prod.price;
        let selectedOffer = this.prod.offers.find(offer => offer.id === this.selectedOfferId);
        if (selectedOffer) {
          finalPrice = selectedOffer.unit * this.prod.price;
        }
        let nameOffer
        if(selectedOffer){
          nameOffer = selectedOffer.name
        }else{
          nameOffer = ''
        }
        let productForCart = {
          product: this.prod,
          qty: this.counter, // используйте this.counter вместо жестко заданной 1
          id: this.selectedOfferId ? this.selectedOfferId : this.prod.id,
          price: finalPrice,
          name: nameOffer
        };
        this.$emit('addToCart', productForCart);
        this.counter = 1;
        this.addProdPopup();
      },
      addProdPopup(){
        document.querySelector('.addProdPopup').classList.toggle('active');
        setTimeout(() => {
          document.querySelector('.addProdPopup').classList.toggle('active');
        }, 3000)
       }
    },
  };
  </script>

<style>
.product_wrapper{
  display: flex;
  align-items: center;
}
.product_action_wrapper{
  width: 100%;
}
</style>
