<template>
    <div class="container">
        <section class="products"
            v-for="category in PRODUCTS"
            :key="category.id"
        >
            <div class="products_title">{{ category.name }}</div>
            <div class="products_wrapper">
                <vCatalogItem
                    v-for="products in category.items[0]"
                    :key="products.id"
                    :prod="products"
                    @addToCart="addToCart"
                />
            </div>
        </section>
    </div>
</template>

<script>
import vCatalogItem from '@/components/v-catalog-item';
import dbData from '../../db.json';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "v-catalog",
    components: {
        vCatalogItem,
    },
    data() {
        return {
            ...mapActions([
                'GET_PRODUCTS_FROM_API',
                "ADD_TO_CART",
                "INIT_CART"
            ]),
            products: dbData
        }
    },
    computed: {
        ...mapGetters([
            "PRODUCTS"
        ]),
    },
    methods: {
        addToCart (data){
            this.ADD_TO_CART(data);
        }
    },
    mounted() {
        this.GET_PRODUCTS_FROM_API();
        if(localStorage.getItem('cart_data')){
            this.INIT_CART();
        }
    }

}
</script>
