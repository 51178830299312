<template>
    <div class="cart_item">
        <div class="cart_item_wrapper">
            <div class="cart_item_box">
                <img :src="cart_item_data.product.images[0]" alt="">
            </div>
            <div class="cart_item_box_content">
                <div class="cart_item_title_wrapper">
                    <a href="#" class="cart_item_title">
                        {{ cart_item_data.product.title }}
                    </a>
                    <div class="cart_item_price">
                        <span v-if="cart_item_data.price">{{ cart_item_data.price.toFixed(2) }}</span><span v-else>{{cart_item_data.product.price.toFixed(2)}}</span> MDL.
                    </div>
                </div>
                <div class="cart_item_descr">
                    <div class="cart_item_descr_label">
                        {{ cart_item_data.name }}
                    </div>
                </div>
                <div class="product_action">
                <div class="product_minus" @click="changeCounter(-1)">
                -
                </div>
                <div class="product_score">{{ counter }}</div>
                <div class="product_plus" @click="changeCounter(1)">
                    +
                </div>
                </div>
            </div>
            <div class="cart_delete" @click="deleteCartItem()">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 24 24">
                    <path d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z"></path>
                </svg>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: "v-cart-item",
  props: {
    cart_item_data: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      localQty: this.cart_item_data && this.cart_item_data ? this.cart_item_data.qty : 0,

    };
  },
  computed: {
    counter: {
      get() {
        return this.localQty;
      },
      set(value) {
        // Вместо изменения this.cart_item_data[0].qty напрямую, эмиттируйте событие
        this.$emit('addToCart', this.cart_item_data, value);
        this.$store.commit('updateCartItemQty', {
          productId: this.cart_item_data.id,
          newQty: value,
        });
      },
    },
  },
  emits: ['addToCart', 'deleteCartItem'],
  watch: {
    cart_item_data: {
      handler(newValue) {
        this.localQty = newValue && newValue ? newValue.qty : 0;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    changeCounter(num) {
      const newValue = this.localQty + parseInt(num);
      if (!isNaN(newValue) && newValue > 0) {
        this.localQty = newValue;
        // Используйте set для эмитта значения counter
        this.counter = newValue;
      }
    },
    addToCartClick() {
      this.$emit('addToCart', this.cart_item_data[0], this.localQty);
    },
    deleteCartItem() {
      this.$emit('deleteCartItem');
    },
  },
};</script>
<style>
    .cart_delete{
        box-shadow: 0px 0px 3px rgba(0,0,0,0.34);
        margin-left: 10px;
        cursor: pointer;
    }
    .cart_delete:active{
        box-shadow: unset;
    }
    .cart_delete svg{
        padding: 0 2px;
        width: 17px;
    }
</style>
