<template>
   <section class="contact_form" @click="hideForm">
     <div class="hide_form" @click="hideForm">
       <svg class="t-popup__close-icon" width="23px" height="23px" viewBox="0 0 23 23" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g stroke="none" stroke-width="1" fill="#fff" fill-rule="evenodd"><rect transform="translate(11.313708, 11.313708) rotate(-45.000000) translate(-11.313708, -11.313708) " x="10.3137085" y="-3.6862915" width="2" height="30"></rect><rect transform="translate(11.313708, 11.313708) rotate(-315.000000) translate(-11.313708, -11.313708) " x="10.3137085" y="-3.6862915" width="2" height="30"></rect></g></svg>
     </div>
        <form action="" class="form">
          <div class="form_title">
            Trimite comanda
          </div>
            <div class="input_wrapper">
                <label for="" class="input_label">Nume</label>
                <input type="text" class="input name">
            </div>
            <div class="input_wrapper">
                <label for="" class="input_label">Prenume</label>
                <input type="text" class="input last_name">
            </div>
            <div class="input_wrapper">
                <label for="" class="input_label">Telefon</label>
                <input type="text" class="input phone">
            </div>
            <div class="input_wrapper">
                <div class="input_wrapper_box">
                    <label for="home" class="input_label" @click="checked">
                        Livrare acasa
                    </label>
                    <input type="radio" name="delivery" id="home" class="input-radio" @click="checked">
                </div>
                <div class="input_wrapper_box">
                    <label for="store" class="input_label" @click="checked">
                        Ridicare la magazin
                    </label>
                    <input type="radio" name="delivery" id="store" class="input-radio" @click="checked">
                </div>
            </div>
            <div class="delivery">
                <label for="" class="input_label">Adresa</label>
                <input type="text" class="input home_adress">
            </div>
            <div class="checkup">
                <label for="" class="input_label">Selectati magazinul</label>
                <select name="" id="" class="store_adress">
                    <option value="Centru">Centru</option>
                    <option value="Râșcani">Râșcani</option>
                    <option value="Botanica">Botanica</option>
                    <option value="Ciocana">Ciocana</option>
                </select>
            </div>
            <div class="method_payments">
                <div class="input_wrapper_box">
                    <label for="cash" class="input_label">
                        Cash la livrare
                    </label>
                    <input type="radio" name="payments" id="cash" class="input-radio" @click="typePayments">
                </div>
                <div class="input_wrapper_box">
                    <label for="card" class="input_label">
                        Card
                    </label>
                    <input type="radio" name="payments" id="card" class="input-radio" @click="typePayments">
                </div>
            </div>
            <div class="send_btn btn" @click="submitForm">
                    {{ message }}
            </div>
        </form>
    </section>
    <section class="show_popup">
        <div class="show_popup_content">
            <div class="show_popup_title"></div>
        </div>
    </section>
    <section class="cart">
        <div class="cart_content">
            <div class="cart_box">
              <div class="cart_title">
                Cos
              </div>
              <div class="cart_close_btn" @click="toggleCart">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 24 24">
                  <path d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z"></path>
                </svg>
              </div>
            </div>
            <div class="cart_wrapper">
                <vCartItem
                    v-for="(cartItem, index) in cart_data"
                    :key="cartItem.id"
                    :quantity="cartItem.qty"
                    :cart_item_data="cartItem"
                    @deleteCartItem="deleteCartItem(index)"
                />
                <div v-if="cart_data.length === 0" class="no_products">
                    <svg xmlns="http://www.w3.org/2000/svg" width="129.188" height="114.562" viewBox="0 0 129.188 114.562">
                    <path class="cls-1" d="M710.628,516.914a12.689,12.689,0,0,0,0,25.378A12.689,12.689,0,1,0,710.628,516.914Zm67.374,0a12.689,12.689,0,1,0,0,25.378A12.689,12.689,0,0,0,778,516.914Zm19.942-70.42a5.206,5.206,0,0,0-4.068-1.949H698.271L693.3,431.107a5.206,5.206,0,0,0-4.88-3.4H675.11a5.206,5.206,0,0,0,0,10.411h9.683L709.557,505a5.2,5.2,0,0,0,4.88,3.389c0.207,0,.417-0.013.624-0.027l69.421-8.331a5.218,5.218,0,0,0,4.473-4.046l10.019-45.108A5.215,5.215,0,0,0,797.944,446.494Zm-14.018,24.079h-20.8V454.956H787.4Zm-46.826,0V454.956h20.825v15.617H737.1Zm20.825,5.205v16.953L737.1,495.225V475.771h20.825v0.007Zm-26.031-20.822v15.617H707.906l-5.781-15.617h29.769Zm-22.059,20.822h22.059v20.084l-14,1.681Zm53.3,16.329V475.778h19.643l-3.186,14.35Z" transform="translate(-669.906 -427.719)"/>
                    </svg>
                    <div class="empty">
                        Coșul dvs. este gol
                    </div>
                </div>
            </div>
            <div class="cart_totals">
                <div class="cart_totals_wrapper">
                    <div class="cart_totals_title">
                        (produse: {{ total }}) <strong>Total:</strong>
                    </div>
                    <div class="cart_totals_price">{{ totalPrice }} MDL.</div>
                </div>
                <div class="cart_totals_btn btn" v-if="total" @click="toggleForm">
                    Finalizeaza comanda

                </div>
            </div>
        </div>
    </section>
</template>

<script>
import vCartItem from '@/components/v-cart-item'
import { mapActions } from 'vuex';
import axios from 'axios';
export default {
    name: "v-cart",
    components: {
        vCartItem,
    },
    props: {
      cart_data: {
        type: Array,
        default() {
            return []
        },
      },
    },
    data() {
        return{
            message: "Trimite"
        }
    },
    computed: {
        total() {
            const totalQuantity = this.cart_data.reduce((total, item) => total + item.qty, 0);
            return totalQuantity;

        },
        totalPrice() {
            const totalPrice = this.cart_data.reduce((total, item) => {
                const product = item;
                const quantity = product.qty;
                const price = product.price;
                const productTotal = quantity * price;
                return total + productTotal;
            }, 0);
            return totalPrice.toFixed(2);
        }
    },
    methods: {
        ...mapActions([
            'DELETE_FROM_CART',
            'CLEAR_CART'
        ]),
        deleteCartItem(index){
            this.DELETE_FROM_CART(index);
        },
        clearCart(){
            this.CLEAR_CART();
        },
        toggleCart(){
          document.querySelector('.cart').classList.toggle('active')
        },
        typePayments(){
            if(document.querySelector("#card").checked){
                this.message = "Achita acum";
            }else{
                this.message = "Trimite";
            }
        },
        checked(){
            let home = document.querySelector('#home');
            let store = document.querySelector('#store');
            if(home.checked === true){
                document.querySelector('.delivery').style.display='block';
            }else{
                document.querySelector('.delivery').style.display='none';
            }
            if(store.checked === true){
                document.querySelector('.checkup').style.display='block';
            }else{
                document.querySelector('.checkup').style.display='none';
            }
        },
        toggleForm() {
            const contactForm = document.querySelector('.contact_form');
            const form = document.querySelector('.form');
            form.addEventListener('click', (e) => {
                e.stopPropagation();
            })
            contactForm.addEventListener('click', () => {
                contactForm.classList.remove('active');
            })
            contactForm.classList.toggle('active');

        },
        async submitForm() {
            if (this.isFormValid()) {
                try {
                    const cartItems = this.cart_data.map(function(item) {
                        var product = item[0];
                        return {
                            name: product.title,
                            quantity: product.qty,
                            price: product.price
                        };
                    });

                    const totalPrice = cartItems.reduce(function(sum, item) {
                        return sum + (item.price * item.quantity);
                    }, 0);

                    const response = await axios.post('https://jsonplaceholder.typicode.com/posts', {
                        name: document.querySelector('.name').value,
                        last_name: document.querySelector('.last_name').value,
                        phone: document.querySelector('.phone').value,
                        typeDelivery: document.querySelector('#home').checked ? "Livrare acasa" : "Ridicare la magazin",
                        address: document.querySelector('#home').checked ? document.querySelector('.home_adress').value : document.querySelector('.store_adress').value,
                        typePayments: document.querySelector('#cash').checked ? "Cash" : "Card",
                        cart_products: cartItems,
                        total_price: totalPrice.toFixed(2)
                    });

                    this.showPopup('Успешно отправлено!');
                    console.log(response.data);

                    // Очищаем значения формы и сбрасываем состояние корзины
                    document.querySelector('.name').value = '';
                    document.querySelector('.last_name').value = '';
                    document.querySelector('.phone').value = '';
                    document.querySelector('#home').checked = false;
                    document.querySelector('.home_adress').value = '';
                    document.querySelector('#store').checked = false;
                    document.querySelector('#cash').checked = false;
                    document.querySelector('#card').checked = false;
                    this.clearCart();
                } catch (error) {
                    this.showPopup('Ошибка отправки данных', error);
                }
            } else {
                this.showPopup('Заполните все поля формы!');
            }
        },
        showPopup(text){
            this.toggleForm()
            const title = document.querySelector('.show_popup_title');
            const showPopup = document.querySelector('.show_popup');

            showPopup.classList.add('active');
            title.innerHTML = text
            setTimeout(() => {
                showPopup.classList.remove('active');
            }, 800)
        },
        isFormValid() {
            let status = false;

            if( document.querySelector('.name').value &&
                document.querySelector('.last_name').value &&
                document.querySelector('.phone').value &&
                (document.querySelector('#home').checked || document.querySelector('#store').checked)){

                    if((document.querySelector('#home').checked && document.querySelector('.home_adress').value) || (document.querySelector('#store').checked && document.querySelector('.store_adress').value)){
                        if(document.querySelector('#cash').checked || document.querySelector('#card').checked){
                            status = true;
                        }
                    }else{
                        status = false
                    }
            }
            return status;
        }

    }
}
</script>

<style>
.show_popup{
    position: fixed;
    background: rgba(0,0,0,0.2);
    left: 0;
    z-index: 9999;
    top: 0;
    width: 100%;
    height: 100vh;
    opacity: 0;
    visibility: hidden;
    transition: 0.8s all;
}
.show_popup.active{
    opacity: 1;
    visibility: visible;
    transition: 0.8s all;
}
.show_popup_content{
    position: absolute;
    display: flex;
    justify-content: center;
    top: 50%;
    left: 50%;
    background: #fff;
    max-width: 400px;
    width: 100%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transform: translate(-50%, -50%);
}
.contact_form{
    position: fixed;
    background: rgba(0,0,0,0.6);
    left: 0;
    z-index: 999;
    top: 0;
    width: 100%;
    height: 100vh;
    opacity: 0;
    visibility: hidden;
}
.contact_form.active{
    opacity: 1;
    visibility: visible;
}
.send_btn{
  height: 50px;
  display: none;
}
.send_btn.btn, .cart .cart_totals_btn.btn{
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
    background: #fff;
    max-width: 560px;
    width: 100%;
    border: 1px solid #ccc;
    padding: 40px 45px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form_title{
  font-size: 36px;
  line-height: 1.23;
  text-align: center;
  font-weight: 600;
  margin-bottom: 24px;
}

.input_wrapper {
    margin-bottom: 20px;
}

.input_label {
    display: block;
    font-size: 20px;
    line-height: 1.55;
    font-weight: 300;
}

.hide_form{
  position: absolute;
  width: 23px;
  font-size: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.input {
    box-sizing: border-box;
    border: 1px solid #c9c9c9;
    border-radius: 4px;
    margin: 0;
    margin-top: 5px;
    height: 60px;
    padding: 0 20px;
    font-size: 16px;
    line-height: 1.33;
    width: 100%;
}

.input_wrapper_box {
    display: flex;
    align-items: center;
}

.delivery,
.checkup {
    margin-bottom: 20px;
}

.checkup select {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.method_payments {
    display: flex;
}

.method_payments .input_wrapper_box {
    margin-right: 10px;
}

.input-radio {
    box-shadow: 0px 0px 0px 1px #6d6d6d;
    font-size: 3em;
    width: 15px;
    height: 15px;
    margin-right: 7px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    border: 4px solid #fff;
    background-clip: border-box;
    border-radius: 50%;
    appearance: none;
    transition: background-color 0.3s, box-shadow 0.3s;

}

.store_adress{
  width: 100%;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  height: 60px;
  padding: 0 45px 0 20px;
  outline: 0;
  border: 0;
  border-radius: 0;
  background: 0 0;
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
  color: #000000;
  border: 1px solid #c9c9c9;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  line-height: 1.33;
}
.checkup{
  position: relative;
}
.checkup::before{
  content: ' ';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 5px 0;
  border-color: #000 transparent transparent;
  position: absolute;
  right: 20px;
  top: 38px;
  bottom: 0;
  margin: auto;
  pointer-events: none;
}

.input-radio:checked {
    box-shadow: 0px 0px 0px 4px #f19e77;
    background-color: #f19e77;
}

.delivery, .checkup{
    display: none;
}

.input_wrapper_box{
    margin-bottom: 5px;
}
.input_wrapper_box:last-child{
    margin-bottom: 0;
}
.cls-1 {
    fill-rule: evenodd;
    opacity: 0.3;}
.no_products{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
}
.empty{
    margin-top: 15px;
    color: #bababa;
    text-align: center;
    font-size: 36px;
}
</style>
